function showAccordion() {
    $('.m-accordion__title-wrapper').click(function (e) {
        const group = $(e.target).closest('.m-accordion__group');
        const text = group.find('.m-accordion__text-wrapper');

        group.toggleClass('is-active');
        // eslint-disable-next-line jquery/no-slide
        text.slideToggle('fast');
    });

    /**
     * Open accordion if url-hash exists
     **/
    (function () {
        const anchor = location.hash
            ? document.querySelector(location.hash)
            : '';
        if (!anchor) {
            return;
        }
        if (!anchor.closest('[data-module="showAccordion"]')) {
            return;
        }
        setTimeout(function () {
            anchor.click();
        }, 700);
    })();
}

export default showAccordion;
