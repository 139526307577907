/*
 * Search Bar functionality
 * Show/Hide popular searched dropdown
 * Show/Hide closing button
 */

class SearchBoxB1 {
    _searchFields = null;

    _popularSearchesDropdown = null;

    constructor() {
        this._initElements();
        this._initListeners();
    }

    _initElements() {
        this._searchFields = document.querySelectorAll(
            '.m-search-box-b__search-field',
        );
        this._popularSearchesDropdown = document.querySelector(
            `.m-popular-searches-a-1`,
        );
    }

    _initListeners() {
        this._searchFields.forEach((searchField) => {
            searchField.addEventListener(
                'focusin',
                this._searchFieldsFocusInHandler.bind(this),
            );
            searchField.addEventListener(
                'focusout',
                this._searchFieldsFocusOutHandler.bind(this),
            );
            searchField.addEventListener(
                'keyup',
                this._searchFieldsKeyupHandler.bind(this),
            );
        });
    }

    _searchFieldsFocusInHandler(event) {
        const field = event.target;

        if (field.value.length) {
            this._hidePopularSearchesDropdown();
        } else {
            this._showPopularSearchesDropdown();
        }
    }

    _searchFieldsFocusOutHandler() {
        this._hidePopularSearchesDropdown();
    }

    _searchFieldsKeyupHandler(event) {
        const field = event.target;

        if (field.value.length) {
            this._hidePopularSearchesDropdown();
        } else {
            this._showPopularSearchesDropdown();
        }
    }

    _hidePopularSearchesDropdown() {
        if (!this._popularSearchesDropdown) {
            return;
        }

        this._popularSearchesDropdown.style.display = 'none';
    }

    _showPopularSearchesDropdown() {
        if (!this._popularSearchesDropdown) {
            return;
        }

        this._popularSearchesDropdown.style.display = 'block';
    }

    _hideAutocompleteDropdown() {
        const dropdowns = document.querySelectorAll(`.algolia-autocomplete`);
        dropdowns.forEach((dropdown) => {
            dropdown.style.display = 'none';
        });
    }
}

function searchBarB() {
    new SearchBoxB1();
}

export default searchBarB;
