import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';

function latestPostsA() {
    Swiper.use([Autoplay, Navigation, Pagination]);

    new Swiper('.js-slider-latest-posts-a-1', {
        loop: false,
        speed: 600,
        watchOverflow: true,
        autoplay: {
            delay: 7000,
        },
        slidesPerView: 1,
        spaceBetween: 20,
        breakpoints: {
            990: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            1199: {
                slidesPerView: 3,
                spaceBetween: 35,
            },
        },
        navigation: {
            prevEl: '.js-slider-latest-posts-a-1-btn-prev',
            nextEl: '.js-slider-latest-posts-a-1-btn-next',
        },
    });
}

export default latestPostsA;
