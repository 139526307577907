function hero() {
    $('.o-hero .a-title')
        .addClass('overflow-hidden')
        .each(function () {
            let words = $(this)
                .html()
                .trim()
                .replace(/\r?\n|\r/g, '')
                .split(' ');
            $(this)
                .empty()
                .html(function () {
                    for (let i = 0; i < words.length; i++) {
                        if (i === 0) {
                            $(this).append(
                                `<span class="inline-block" data-aos="translateY100-fade-in-up" data-aos-anchor-placement="top-bottom" data-aos-delay="0">${words[i]}</span>`,
                            );
                        } else {
                            $(this).append(
                                ` <span class="inline-block" data-aos="translateY100-fade-in-up" data-aos-anchor-placement="top-bottom" data-aos-delay="${i}00">${words[i]}</span>`,
                            );
                        }
                    }
                });
        });
}

export default hero;
