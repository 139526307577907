import Swiper, { Autoplay, Pagination } from 'swiper';

function hero5() {
    Swiper.use([Autoplay, Pagination]);

    new Swiper('.js-slider-swiper-hero-5', {
        loop: true,
        speed: 700,
        autoplay: {
            delay: 5000,
        },
        slidesPerView: 1,
        spaceBetween: 0,
        pagination: {
            el: '.js-slider-swiper-hero-5-pagination',
            clickable: true,
        },
        on: {
            slideChangeTransitionStart: function () {
                let activeItemTitle = $(
                    '.js-slider-swiper-hero-5 .swiper-slide-active .a-title',
                );

                activeItemTitle.addClass('overflow-hidden');

                let words = activeItemTitle
                    .text()
                    .trim()
                    .replace(/\r?\n|\r/g, '')
                    .split(' ');

                activeItemTitle.empty().html(function () {
                    for (let i = 0; i < words.length; i++) {
                        if (i === 0) {
                            activeItemTitle.append(
                                `<span class="inline-block" data-aos="translateY100-fade-in-up" data-aos-anchor-placement="top-bottom" data-aos-delay="0">${words[i]}</span>`,
                            );
                        } else {
                            activeItemTitle.append(
                                ` <span class="inline-block" data-aos="translateY100-fade-in-up" data-aos-anchor-placement="top-bottom" data-aos-delay="${i}00">${words[i]}</span>`,
                            );
                        }
                    }
                });
            },
        },
    });
}

export default hero5;
